<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CIcon name="cil-justify-center"/>
            <strong> {{ $lang.notification.title }} </strong>
          </CCardHeader>
          <CCardBody>
            <CListGroup v-if="count > 0">
              <CListGroupItem @click="getURL(notification)" class="flex-column align-items-start"
                              v-for="(notification,index) in notifications" :key="index">
                <div class="d-flex w-100 justify-content-between">
                  <h6 class="mb-1" v-html="notification.notification"></h6>
                  <small class="text-muted">{{ notification.createdAt }}</small>
                </div>
              </CListGroupItem>
              <button @click="btnHandleScroll()" class="btn btn-primary" v-if="btn_dis>0">
                {{ $lang.notification.load_more }}
              </button>
            </CListGroup>
            <CListGroup v-else>
              <CListGroupItem class="flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                  <h6 class="mb-1">{{ $lang.common.label.not_found }}</h6>
                </div>
              </CListGroupItem>
            </CListGroup>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import store from "/src/store/store.js";
import {General} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
// import preloader from "/src/components/layouts/preloader/preloader";
// import required_span from "/src/components/layouts/general/required-span"
export default {
  name: 'NotificationDetail',
  mixins: [Mixin],
  components: {
    // preloader,
    // required_span,
  },
  data() {
    return {
      self: this,
      module: General,
      notifications: [],
      count: 0,
      page: 1,
      to: 0,
      btn_dis: 1,
    }
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    // eslint-disable-next-line no-undef
    axios.post(this.notificationAllUrlApi(this.module, self.page))
        .then((response) => {
          self.notifications = response.data.data;
          self.count = response.data.total;
          self.to = response.data.to;
          if (response.data.lastPage === self.page) {
            self.btn_dis = 0;
          }
        });
    store.commit('showLoader', false);
  },
  methods: {
    getURL(notification) {
      const type = notification.type;
      const id = notification.typeId;
      this.notificationRedirect(type, id);
    },
    btnHandleScroll() {
      let self = this;
      self.page = self.page + 1;
      if (self.to < self.count) {
        // eslint-disable-next-line no-undef
        axios.post(this.notificationAllUrlApi(this.module, self.page))
            .then((response) => {
              self.notifications = self.notifications.concat(response.data.data);
              self.count = response.data.total;
              self.to = response.data.to;
              if (response.data.lastPage === self.page) {
                self.btn_dis = 0;
              }
            });
      }
    }
  }
}
</script>
<style>
div .error {
  color: #ff0000;
}

.hasError label {
  color: red;
}
</style>
